<template>
  <div class="border-bottom">
    <b-form-group
      label-for="select-evaluation_situation"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <template #label>
        {{
          $getVisibleNames(
            "teaching.evaluationsituation",
            false,
            "Situación Evaluativa"
          )
        }}
        {{ index + 1 }}: <button-delete @click="removeTest"> </button-delete>
      </template>
      <b-form-select
        id="select-evaluation_situation"
        v-model="test.evaluation_situation"
        :options="evaluation_situations"
        value-field="id"
        text-field="name"
        aria-describedby="select-evaluation_situation-feedback"
        size="sm"
        class="mt-1"
      >
      </b-form-select>
      <b-form-invalid-feedback id="select-evaluation_situation-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Ponderación"
      label-for="input-weighing"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
      :description="`${
        parseFloat(maxWeighingTest) >= 0 && test.weighing >= 0
          ? 'Disponible ' + maxWeighingTest + '%'
          : ''
      }`"
    >
      <b-form-input
        id="input-weighing"
        v-model="test.weighing"
        :state="test.weighing >= 0 && maxWeighingTest >= 0"
        min="0"
        type="number"
        aria-describedby="input-weighing-feedback"
        size="sm"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      label="Agente Evaluativo"
      label-for="select-evaluative_agent"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="select-evaluative_agent"
        v-model="test.instrument_type.evaluative_agent"
        :options="instrument_types"
        value-field="id"
        text-field="name"
        aria-describedby="select-evaluative_agent-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="select-evaluative_agent-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Tipo de Trabajo:"
      label-for="select-work_type"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="select-work_type"
        v-model="test.work_type"
        :options="work_types"
        value-field="id"
        text-field="value"
        aria-describedby="select-work_type-feedback"
        size="sm"
        class="mt-1"
      ></b-form-select>
      <b-form-invalid-feedback id="select-work_type-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Examinador:"
      label-for="select-examiner"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="select-examiner"
        v-model="test.examiner"
        :options="examiner_types"
        value-field="id"
        text-field="value"
        aria-describedby="select-examiner-feedback"
        size="sm"
        class="mt-1"
      ></b-form-select>
      <b-form-invalid-feedback id="select-examiner-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "../../../utils/utils";

export default {
  name: "TestComponent",
  components: {},
  props: {
    evaluation_id: {},
    index: {
      type: Number,
    },
    Test: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          title: "",
          description: "",
          type: null,
          weighing: 0,
          max_score: 100,
          work_type: 1,
          examiner: 3,
          evaluation_situation: null,
          visible_by_evaluatees: false,
          question_creation_is_locked: false,
          student_can_upload_attachments: false,
          allow_group_creation: false,
          student_can_auto_evaluate: false,
          descriptor_creation_is_locked: false,
          segment_creation_is_locked: false,
          start_time: null,
          end_time: null,
          publish_date: null,
          evaluation: this.evaluation_id,
          test_type_default: null,
          files: [],
          files_for_professor: [],
          time: null,
        };
      },
    },
    TestList: {
      type: Array,
    },
    evaluation_situations: {
      type: Array,
    },
  },
  data() {
    return {
      test: {
        ...this.Test,
        instrument_type: {
          id: generateUniqueId(),
          evaluative_agent: null,
          test: this.Test.id,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      work_types: names.TEST_WORK_TYPES,
      examiner_types: names.TEST_EXAMINER_TYPES,
      instrument_types: names.NEW_TEST_TYPES,
    }),
    maxWeighingTest() {
      let max_weighing = this.TestList.filter(
        (x) => x.evaluation == this.evaluation_id && x.id != this.test.id
      ).reduce((accumulator, x) => {
        return accumulator - x.weighing;
      }, 0);
      max_weighing += 100;
      if (this.test.weighing && parseFloat(this.test.weighing) > 0)
        max_weighing -= parseFloat(this.test.weighing);
      return max_weighing;
    },
  },
  methods: {
    removeTest() {
      if (!isNaN(this.test.id)) {
        this.$swal({
          title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
            "teaching.evaluationsituation",
            false,
            "Situación Evaluativa"
          )}"`,
          text: "¡Esta acción no se podrá revertir al guardar!",
          type: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            this.test.delete_test = true;
            this.$emit("change_test_value", this.test);
          }
        });
      } else this.$emit("removeTest", this.test.id);
    },
    fetchTestsInfo() {
      if (this.test.test_type_default != null) {
        this.$restful
          .Get(
            `/evaluations2/test-type-default/${this.test.test_type_default}/`
          )
          .then((response) => {
            this.test.instrument_type = response;
            if (
              this.test.evaluation_situation == null &&
              this.evaluation_situations.length > 0
            )
              this.test.evaluation_situation = this.evaluation_situations[0].id;
            if (
              this.test.instrument_type.evaluative_agent == null &&
              this.instrument_types.length > 0
            )
              this.test.instrument_type.evaluative_agent =
                this.instrument_types[0].id;
          });
      }
    },
  },
  watch: {
    test: {
      handler() {
        this.$emit("change_test_value", this.test);
      },
      deep: true,
    },
  },
  mounted() {
    if (
      this.test.evaluation_situation == null &&
      this.evaluation_situations.length > 0
    )
      this.test.evaluation_situation = this.evaluation_situations[0].id;
    if (
      this.test.instrument_type.evaluative_agent == null &&
      this.instrument_types.length > 0
    )
      this.test.instrument_type.evaluative_agent = this.instrument_types[0].id;
  },
  created() {
    this.fetchTestsInfo();
  },
};
</script>

<style scoped>
</style>

